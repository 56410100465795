.navBar {
    background-color: black;
}

.navBarLogo {
    margin-left: 20px;
    color: rgb(238, 238, 238);
    font-size: 30px;
}

.navBarDropdown {
    font-size: 18px;
}

.navItem {
    padding: 5px 10px 5px 10px;
    color: rgb(238, 238, 238);
}

.navItem:hover {
    color: red;
}

.navItem:active {
    color: darkred;
}

.joinOurTeamLink {
    font-weight: 100;
}

.navCollapseElement {
    color: rgb(238, 238, 238);
}

.navLinksCollapsed {
    width: 70%;
}

.navLinksSmallCollapsed {
    width: 100%;
    margin-top: -40px;
}
