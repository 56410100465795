.servicesTable {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    font-weight: 600;
}

.subtablesRow {
    vertical-align: top;
}

.serviceSection {
    text-align: left;
    width: 50%;
    font-weight: 900;
    font-size: 19px;
}

.service {
    text-align: left;
    width: 50%;
    padding-bottom: 5px;
}

.price {
    text-align: right;
    width: 50%;
}

.serviceRow:hover {
    background-color: rgba(250, 250, 250, 0.65);
}

.leftTable {
    padding-right: 15px;
    width: 50%;
}

.lt {
    width: 100%;
}

.rt {
    width: 100%;
}

.rightTable {
    padding-left: 15px;
    width: 50%;
}

.pricingNote {
    margin-top: 10px;
}

.ajdustmentsNote {
    font-size: 12px;
}
