.appName {
    font-size: 38pt;
    margin-bottom: 30px;
    color: black;
}

.appDescription {
    margin-bottom: 24px;
}

.homePage {
    margin-bottom: 90px;
}
