.App {
    text-align: center;
    padding-top: 70px;
    padding-bottom: 147px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.backgroundImage {
    background-image: url('../public/images/mural.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    margin-top: 77px;
}

.background {
    background-color: rgb(77, 77, 77);
    margin-top: 77px;
}

.pageContainer {
    background-color: rgba(250, 250, 250, 0.75);
    padding: 50px;
}

.pageTitle {
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 30px;
    text-align: center;
}

.subtitle {
    font-size: 19px;
    font-weight: 900;
    padding-bottom: 10px;
}
