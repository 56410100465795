.contactUs {
    text-align: left;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.day {
    padding-right: 60px;
}

.mapLink {
    color: black;
    font-style: italic;
    font-weight: 400;
}

.mapLink:hover {
    color: red;
}

.mapLink:active {
    color: darkred;
}

/*
.map {
    width: 500px;
    height: 500px;
}
*/
