.bottomNavTable {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.socials {
    text-align: center;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}
